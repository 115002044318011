import {HeaderContainer, HeaderWrapper, UserInfo} from './styles';

import now from '../../assets/images/now.png';
import { useNavigate } from 'react-router';
import { IoIosNotifications } from "react-icons/io";

const Header = (props:any) => {
   
    const navigate = useNavigate();

    const handleLogoff = () => {
        sessionStorage.removeItem("userToken");
        sessionStorage.removeItem("message");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("dados");
        navigate('/signin');
    }
    return (
        <HeaderContainer>
            <HeaderWrapper>
                   <img src={'/images/'+props.image+'.bmp'} width={172} height={61} alt="now" />
                <UserInfo>           
                  <div className='username'>
                      <p>Olá, <span className="primary-color font-bold">{props.username}</span></p>
                      <a href="#" onClick={handleLogoff}>Sair</a>
                  </div>
                </UserInfo>
            </HeaderWrapper>
        </HeaderContainer>
    )
}

export default Header