import axios from 'axios';

const api = axios.create({
    baseURL: 'https://api.adlim.com.br:3333'
    
    //'http://192.168.5.125:3333'
    //'https://api.adlim.com.br:3333/'
    //https://34.207.249.36:3333/
    //https://192.168.5.22:3333/
    //https://os.solt.com.br:3333/
})

export default api;



